import React from 'react';
import {Link} from 'react-router-dom';
import { IonContent, IonHeader, IonTitle, IonToolbar, IonRow, IonCol, IonGrid, IonText, IonButton, IonButtons } from '@ionic/react';
import './SavePassSuccess.css';

const SavePassSuccess: React.FC = () => {
  return (
    <IonContent className='blackBG'>
      <IonHeader>
        <IonToolbar className='toolbarTrans'>
          <IonButtons slot="end" className='btnCloseRecuperar'>
            <IonButton>
              <img src='./assets/img/cerrar.png' alt=''/>
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <IonToolbar className='toolbarTrans'>
          <IonTitle className='ion-text-center'>
            <img src='./assets/carnot_logo.png' className='imgRecuperar' alt=""/>
          </IonTitle>
        </IonToolbar>
        <IonText color="primary">
            <h1 className='textRecuperaTitle'>Cambio de contraseña</h1>
            <h1 className='textRecuperaTitle2'>exitoso</h1>
        </IonText>
      </IonHeader>
      <IonGrid>
        <IonRow>
          <IonCol size-md="2"></IonCol>
          <IonCol size-xs="12" size-md="8">
            <p className='textRecuperar'>
            Su nueva contraseña ha sido guardada exitosamente, ya puede iniciar sesión y acceder a sus registros.
            </p>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size-md="2"></IonCol>
          <IonCol size-xs="12" size-md="8">
            <IonButton expand="block" className="btnSaveSuccess">
              <div className=''> 
                <Link to="/login" className='linkSaveSucess'>Iniciar sesión</Link>
              </div>
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonContent>
  );
};

export default SavePassSuccess;
