import React, {useEffect, useState, useContext } from "react";
import { Link,useLocation  } from 'react-router-dom';
import { IonContent, IonPage, IonText, IonButton } from '@ionic/react';
import './Carlsson.css';
//import globalContext from "../../globalContext"; 


const Puntuacion: React.FC = () => {
    //const total = useContext(globalContext);
    const location = useLocation();
    const [authId, setAuthId] = useState(localStorage.getItem('authId'));
    const [submitDate, setSubmitDate] = useState("");
    const [show_calidad, setShowCalidad] = useState(localStorage.getItem('show_calidad'));
    const [totalDays, setTotalDays] = useState(localStorage.getItem('total_days'));
    const [totalResponsesCalidad, setTotalResponsesCalidad] = useState(localStorage.getItem('total_calidad'));
    
    let puntuacion:any= location.state

    let visible = '';

    let result={
        "user_id":authId,
        "questionnaire_id": "1"
    }
    fetch('https://rombodigital.mx/carnot/apikicab/api/results', {
        method: 'POST',
        body: JSON.stringify(result),
        headers: {
            'Content-Type': 'text/plain',
    }
    })
        .then(res => res.json()) 
        .then(response => {
            if(String(response.message).indexOf('Success')>-1){
                console.log(response)
                console.log(response['created_at'])
                setSubmitDate(response['created_at'])
            }
            
    })
        .catch(error => {
        console.error(error);
    })

    if(Number(totalDays) <= 7 && Number(totalResponsesCalidad) === 0){
    localStorage.setItem('show_calidad','true');
    visible = 'true';
    //console.log('<7');
    } else if((Number(totalDays) >= 8 && Number(totalResponsesCalidad) == 1) && (Number(totalDays) < 14 && Number(totalResponsesCalidad) === 1)){
    localStorage.setItem('show_calidad','true');
    visible = 'true';
    //console.log('>=8');
    } else if((Number(totalDays) >= 14 && Number(totalResponsesCalidad) === 2) && (Number(totalDays) < 35 && Number(totalResponsesCalidad) === 2)){
    localStorage.setItem('show_calidad','true');
    visible = 'true';
    //console.log('>=14');
    } else if((Number(totalDays) >= 35 && Number(totalResponsesCalidad) === 3) && (Number(totalDays) < 42 && Number(totalResponsesCalidad) === 3)){
    localStorage.setItem('show_calidad','true');
    visible = 'true';
    //console.log('>=35');
    } else if((Number(totalDays) >= 42 && Number(totalResponsesCalidad) === 4) && (Number(totalDays) < 49 && Number(totalResponsesCalidad) === 4)){
    localStorage.setItem('show_calidad','true');
    visible = 'true';
    //console.log('>=42');
    } else {
    localStorage.setItem('show_calidad','');
    visible = '';
    //window.location.reload();
    }

    useEffect(() => {
        localStorage.setItem('show_calidad', visible);
      }, [show_calidad]);
    
      /*console.log(show_calidad);
      console.log(totalDays);*/
      //console.log(totalResponsesCalidad);
      console.log(localStorage.getItem('show_calidad'));
    return (
            <IonContent fullscreen>
                <IonText>
                    <h3 className='text title-puntuacion'>¡Gracias!</h3>
                </IonText>
                <img src="./assets/img/check.gif" width="200px" className="img-center pt1"/>

                <IonText>
                    <h3 className='text-sm'><span>Sus respuestas han sido guardadas exitosamente.</span><br /></h3>
                    <h3 className="text-sm txt-points">Puntaje total:</h3>
                    <h3 className="points">{puntuacion['total']}</h3>
                    <h3 className="text-sm txt-lite">Fecha de registro:<br/> {submitDate}</h3>
                    <h3 className="text pl2 pr2 pt2">
                    Por favor continúe con el siguiente cuestionario para completar su primer registro:
                    </h3>
                </IonText>
                {localStorage.getItem('show_calidad') === 'true' &&
                    <>
                        <Link to="../calidad-de-vida" className="link-btn">
                            <IonButton expand="block" size="large" className="btn-go-calidad">
                                Cuestionario Calidad de Vida
                            </IonButton>
                        </Link> 
                    </>
                }
                <Link to="./home1" className="link-btn">
                    <IonButton expand="block" size="large" className="btn-go-calidad">
                        Finalizar
                    </IonButton>
                </Link> 
            </IonContent>
    );
  };
  
  export default Puntuacion;