import React, {useState} from 'react';
import { IonCol, IonRow, IonText, IonAlert, AlertInput } from '@ionic/react';
import './RegistraFecha.css';
import moment from 'moment';

const RegistraFecha: React.FC = () => {
  const [authId, setAuthId] = useState(localStorage.getItem('authId'));
  const [showAlert, setShowAlert] = useState(false);
  const [selectedDate, setSelectedDate] = useState(moment().format('YYYY-MM-DD'));
  const [inputsData, setInputsData] = useState({});

  const handleDateSelection = (input: AlertInput) => {
    setSelectedDate(input.value);
  };

  let startTreatment={
    "user_id": authId,
	  "started_treatment": selectedDate + ' ' + '00:00:00'
  }

  const handleAlertConfirm = async () => {
    console.log(startTreatment);
    fetch('https://rombodigital.mx/carnot/apikicab/api/start-treatment', {
            method: 'POST',
            body: JSON.stringify(startTreatment),
            headers: {
                'Content-Type': 'text/plain',
        }
        })
            .then(res => res.json()) 
            .then(response => {
            if(response.message === 'Success'){
              localStorage.setItem('started_treatment', selectedDate + ' ' + '00:00:00')
            }
        })
            .catch(error => {
            console.error(error);
        })
  };


  return (
    <>
      <IonCol size-xs="12" size-md="12">
        <div className='text-center'>
          <p className='Responda-los-cuestionarios'>¡Registre la fecha!</p>
        </div>
        <div className='divRegistraFecha' onClick={() => setShowAlert(true)}>
          <IonRow className='rowFecha'>
            <IonCol size-xs="4" size-md="5" className='text-center colFecha'>
              <img src='./assets/fecha.png' alt='' className='fechaImg'/>
            </IonCol>
            <IonCol size-xs="8" size-md="7" className='colFecha'>
              <p className='textRegistraFecha1'>
                Registre la fecha de inicio <br /><IonText className='textFecha2'>de su tratamiento</IonText>
              </p>
            </IonCol>
          </IonRow>
        </div>
        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          header={'Seleccionar fecha de inicio del tratamiento'}
          inputs={[
            {
              name: 'fecha',
              type: 'date',
              value: selectedDate,
              handler: handleDateSelection
            },
          ]}
          buttons={[
            {
              text: 'Cancelar',
              role: 'cancel'
            },
            {
              text: 'Aceptar',
              handler: handleAlertConfirm
            }
          ]}
        />
      </IonCol>
    </>
  );
};
export default RegistraFecha;

function setSelectedDate(value: any) {
  throw new Error('Function not implemented.');
}
