import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { IonContent, IonHeader, IonTitle, IonToolbar, IonText, IonGrid, IonRow, IonCol } from '@ionic/react';
import './Home1.css';
import CardSintomas from "../../components/Home/CardSintomas";
import RegistraFecha from "../../components/Home/RegistraFecha";
import SelectDays from "../../components/Home/SelectDays";
import moment from 'moment';

const Home1: React.FC = () => {
  const [authId, setAuthId] = useState(localStorage.getItem('authId'));
  const [totalDays, setTotalDays] = useState(localStorage.getItem('total_days'));
  const [validate_total_days, setValidateTotalDays] = useState(localStorage.getItem('validate_total_days'));
  const [started_treatment, setStartedTreatment] = useState(localStorage.getItem('started_treatment'));
  const [valida_registra_fecha, setValidaRegistraFecha] = useState(localStorage.getItem('valida_registra_fecha'));
  const [show_carlson, setShowCarlson] = useState(localStorage.getItem('show_carlson'));
  const [show_calidad, setShowCalidad] = useState(localStorage.getItem('show_calidad'));
  const [show_text_cuestionarios, setShowTextCuestionarios] = useState(localStorage.getItem('show_text_cuestionarios'));
  
  //localStorage.setItem('total_carlson','');
  //localStorage.setItem('total_calidad','');
  const [totalResponsesCarlson, setTotalResponsesCarlson] = useState(localStorage.getItem('total_carlson'));
  const [totalResponsesCalidad, setTotalResponsesCalidad] = useState(localStorage.getItem('total_calidad'));

  //console.log(localStorage.getItem('total_carlson'));
  //console.log(localStorage.getItem('total_calidad'));

  let totalcarlson = Number(totalResponsesCarlson);
  let totalcalidad = Number(totalResponsesCalidad);

  const totalCuestionario = Math.floor((totalcarlson + totalcalidad)/2);
  
  
  if(Number(totalDays) <= 7) {
    //localStorage.removeItem('validate_total_days');
    localStorage.setItem('validate_total_days', '');
    //setValidateTotalDays('');
  } else {
   //setValidateTotalDays('Han pasado 7 días');
    localStorage.setItem('validate_total_days','Han pasado 7 días');
  }
  
  //localStorage.removeItem('first_syntomp_day');
  if(localStorage.getItem('first_syntomp_day')==null){
    localStorage.setItem('first_syntomp_day','');
    console.log('first_syntomp_day');
  }
  
  // calcula el total de días
  let fecha_first = localStorage.getItem('first_syntomp_day');
  let fecha = moment();
  let fecha_actual = fecha.format('YYYY-MM-DD');
  
  let fechaMoment1 = moment(fecha_first, 'YYYY-MM-DD');
  let fechaMoment2 = moment('2023-03-28', 'YYYY-MM-DD');

  let diferenciaEnDias = fechaMoment2.diff(fecha_first, 'days') + 1;

  //localStorage.setItem('total_days', diferenciaEnDias.toString());

  //valida los días y el incio de tratamiento para mostrar componente RegistraFecha
  if(validate_total_days !== null && started_treatment !== null){
    localStorage.setItem('valida_registra_fecha','true');
  } else if(Number(totalDays) <= 7){
    localStorage.setItem('valida_registra_fecha','true');
  } else {
    localStorage.setItem('valida_registra_fecha','');
  }

  //valida si se muestran los botones de los cuestionarios
  if((Number(totalDays) <= 7 && Number(totalResponsesCarlson) === 0)){
    localStorage.setItem('show_carlson','true');
    //console.log('<7');
  } else if((Number(totalDays) >= 8 && Number(totalResponsesCarlson) == 1) && (Number(totalDays) < 14 && Number(totalResponsesCarlson) === 1)){
    localStorage.setItem('show_carlson','true');
    //console.log('>=8');
  } else if((Number(totalDays) >= 14 && Number(totalResponsesCarlson) === 2) && (Number(totalDays) < 35 && Number(totalResponsesCarlson) === 2)){
    localStorage.setItem('show_carlson','true');
    //console.log('>=14');
  } else if((Number(totalDays) >= 35 && Number(totalResponsesCarlson) === 3) && (Number(totalDays) < 42 && Number(totalResponsesCarlson) === 3)){
    localStorage.setItem('show_carlson','true');
    //console.log('>=35');
  } else if((Number(totalDays) >= 42 && Number(totalResponsesCarlson) === 4) && (Number(totalDays) < 49 && Number(totalResponsesCarlson) === 4)){
    localStorage.setItem('show_carlson','true');
    //console.log('>=42');
  } else {
    localStorage.setItem('show_carlson','');
  }

  

  if(Number(totalDays) <= 7 && Number(totalResponsesCalidad) === 0){
    localStorage.setItem('show_calidad','true');
    //console.log('<7');
  } else if((Number(totalDays) >= 8 && Number(totalResponsesCalidad) == 1) && (Number(totalDays) < 14 && Number(totalResponsesCalidad) === 1)){
    localStorage.setItem('show_calidad','true');
    //console.log('>=8');
  } else if((Number(totalDays) >= 14 && Number(totalResponsesCalidad) === 2) && (Number(totalDays) < 35 && Number(totalResponsesCalidad) === 2)){
    localStorage.setItem('show_calidad','true');
    //console.log('>=14');
  } else if((Number(totalDays) >= 35 && Number(totalResponsesCalidad) === 3) && (Number(totalDays) < 42 && Number(totalResponsesCalidad) === 3)){
    localStorage.setItem('show_calidad','true');
    //console.log('>=35');
  } else if((Number(totalDays) >= 42 && Number(totalResponsesCalidad) === 4) && (Number(totalDays) < 49 && Number(totalResponsesCalidad) === 4)){
    localStorage.setItem('show_calidad','true');
    //console.log('>=42');
  } else {
    localStorage.setItem('show_calidad','');
  }


  //valida texto cuestionarios 
  if(show_carlson === 'true' || show_calidad === 'true'){
    localStorage.setItem('show_text_cuestionarios', 'true');
  } else {
    localStorage.setItem('show_text_cuestionarios', '');
  }
  

  
  useEffect(() => {
    setTotalDays(localStorage.getItem('total_days'));
    setValidateTotalDays(localStorage.getItem('validate_total_days'));
    setValidaRegistraFecha(localStorage.getItem('valida_registra_fecha'));
    setShowCarlson(localStorage.getItem('show_carlson'));
    setShowCalidad(localStorage.getItem('show_calidad'));
    setShowTextCuestionarios(localStorage.getItem('show_text_cuestionarios'));
  });
  
  
  console.log(totalDays);
  console.log(show_carlson);
  console.log(show_calidad);
  console.log(totalResponsesCarlson);
  console.log(totalResponsesCalidad);
  return (
    
    <IonContent className='bllueBG'>
      <IonHeader>
        
          <img src="./assets/img/llamas.gif" width="100px" className="img-center pt1"/>

        <p className='textHome1'>
          A partir de ahora, registre los síntomas presentados en los <IonText className='textCyanHome1'>7 días previos al tratamiento.</IonText>
        </p>

        <p className='textDescHome1'>
          Seleccione una opción<br/>
          (puede registrar más de un síntoma al día):
        </p>
      </IonHeader>
      <IonGrid>
        <IonRow>
          <IonCol size="12" size-xs="12" size-md="12">
            <CardSintomas />
          </IonCol>
          <IonCol size-xs="12" size-md="12">
            <p className='textCompHome'>Complete su primer registro</p>
            <div className='pasosSintomas'>
              <p>{totalCuestionario}/5</p>
            </div>
            <p className='textStepsHome1'>
            <IonText className='textAquaHome1'>7 días antes</IonText> del inicio del tratamiento
            </p>
          </IonCol>
          <IonCol size-xs="12" size-md="12">
            {show_text_cuestionarios === 'true' &&
              <p className="Responda-los-cuestionarios">Responda los cuestionarios:</p>
            }
            {show_carlson === 'true' &&
              <div className='linkCuestionario'>
                <Link to="/carlsson">
                  <img src='./assets/cuestionario-1.png' alt=''/>
                </Link> 
              </div>
            }
            {show_calidad === 'true' &&
              <div className='linkCuestionario'>
                <Link to="/calidad-de-vida">
                  <img src='./assets/cuestionario-2.png' alt=''/>
                </Link> 
              </div>
            }
          </IonCol>
          {valida_registra_fecha === '' &&
              <RegistraFecha />
          }
        </IonRow>
       <SelectDays />
      </IonGrid>
    </IonContent>
  );
};

export default Home1;
