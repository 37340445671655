import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import { IonButton, IonHeader, IonTitle, IonToolbar, IonText, IonContent } from '@ionic/react';
import './Welcome.css';

function Welcome() {
  const [authId, setAuthId] = useState(localStorage.getItem('authId'));
  console.log(authId);
  

  return (
    <IonContent className='blackBG'>
      <video src="./assets/vid/bienvenida2.mp4" muted autoPlay className='homeVideo img-center'></video>
      <IonHeader className='buttons'>
        {authId !== null ?
          <Link to="/home1" className='link-btn'>
            <IonButton expand="block" className='init'>Siguiente</IonButton>
          </Link> :
          <>
            <Link to="/login" className='link-btn'>
              <IonButton expand="block" className='init'>Iniciar sesión</IonButton>
            </Link>
            <Link to="/register" className='link-btn'>
              <IonButton expand="block" className='register'>Registrarse</IonButton>
            </Link>
          </>
        }
        
      </IonHeader>
      
    </IonContent>
  );
}
export default Welcome;