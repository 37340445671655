import React, {useEffect, useState, useRef } from "react";
import { Link, useHistory } from 'react-router-dom';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonText, IonButtons, IonButton, IonRadio, IonRadioGroup, IonItem, IonLabel, useIonAlert  } from '@ionic/react';
import './Carlsson.css';
import MenuCuestionario from "../../components/Carlsson/Cuestionario/MenuCuestionario";
import { log } from "console";
//import globalContext from "../../globalContext"; 

//import useTegoStore from "../../states"


let answersObject:object = {}
let answeredSuboptions:object = {}


const Cuestionario: React.FC = () => {
    const [presentAlert] = useIonAlert();
    const history = useHistory()

    const [questions, setQuestions] = useState([{"question_id": "0","question_text": "","question_order": "0"}]);
    const [questionsLength, setQuestionsLength] = useState(0);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [currentQuestionID, setCurrentQuestionID] = useState(0);
    const [options, setOptions] = useState([{"option_id": "0","option_value": "0","option_text": ""}]);
    const [answers, setAnswers] = useState({});
    const [total, setTotal] = useState({'total':0});
    const [hassubquestions, setHassubquestions] = useState(false);
    const [subquestions, setSubquestions] = useState([]);
    const [suboptions, setSuboptions] = useState(new Array());
    const [selected, setSelected] = useState("");
    const [answeredSuboptionsState, setAnsweredSuboptionsState] = useState(0);
    const [authId, setAuthId] = useState(localStorage.getItem('authId'));
    const [stepNumber, setStepNumber] = useState(localStorage.getItem('stepNumber'));
    if(stepNumber==null){
        localStorage.setItem('stepNumber','0')
    }
    


    //let answeredsuboptions=new Array()

    
    const [loaded, setLoaded] = useState(false);


    function nextQuestion() {
        setSelected("")
        let nextQuestionIndex=Number(currentQuestion)+1
        let nextQuestionID= Number(questions[nextQuestionIndex]['question_id'])
        setCurrentQuestion(nextQuestionIndex)
        fetchOptionsData(nextQuestionID)
        setCurrentQuestionID(nextQuestionID)
        scrollToTop()
    }

    function answer(id,value,text,option){
        setSelected(option)

        let answerObject={}
        answerObject={
            "question_id": Number(id),
            "user_id":authId,
            "value" : value,
            "text" : text
        }
        
        answersObject[currentQuestionID]=answerObject
        fetch('https://rombodigital.mx/carnot/apikicab/api/answers', {
            method: 'POST',
            body: JSON.stringify(answerObject),
            headers: {
                'Content-Type': 'text/plain',
        }
        })
            .then(res => res.text()) 
            .then(response => {
        })
            .catch(error => {
            console.error(error);
        })
    }
    function answerSuboptions(id,value,text,x){
        let answerObject={}
        answerObject={
            "question_id": Number(id),
            "user_id":authId,
            "value" : value,
            "text" : text
        }
        answeredSuboptions[id]="true"
        setAnsweredSuboptionsState(Object.values(answeredSuboptions).length)
        
        answersObject[currentQuestionID]=answerObject
        fetch('https://rombodigital.mx/carnot/apikicab/api/answers', {
            method: 'POST',
            body: JSON.stringify(answerObject),
            headers: {
                'Content-Type': 'text/plain',
        }
        })
            .then(res => res.text()) 
            .then(response => {
        })
            .catch(error => {
            console.error(error);
        })
    }
    function sumbitAnswers() {
        let totalsum=0
        setAnswers(answersObject)
        let arrAnswers=Object.values(answersObject)
        arrAnswers.forEach((item, index, arr)=>{
            totalsum+=Number(item['value'])
            setTotal({'total':totalsum})
        })
        console.log(totalsum);
        

        let step= Number(stepNumber)+1
        localStorage.setItem('stepNumber',step.toString());
        localStorage.setItem('total_carlson',step.toString());
        console.log(localStorage.getItem('total_carlson'));
        


        history.push({pathname:"/puntuacion",
            state:{'total':totalsum,'step':localStorage.getItem('stepNumberC')}
        }) 
    }

    function getClass(num:String) {
        //return "state-"+selected+"/"+num
        if(selected==""){
            return "empty "+num+" / "+selected
        }
        else if(selected==num){
            return "active "+num+" / "+selected
        }else{
            return "inactive "+num+" / "+selected
        }
    }

    const fetchQuestionsData = () => {
        return fetch("https://rombodigital.mx/carnot/apikicab/api/questions/1")
        .then((response) => response.json())
        .then((data) => {            
            setQuestionsLength(Object.keys(data.questions).length)
            let questionsArray:any = Object.values(data.questions)            
            setQuestions(questionsArray)
            let questionID = questionsArray[0]['question_id']
            setCurrentQuestionID(questionID)
            fetchOptionsData(questionID)
        })
    }
    const fetchOptionsData = (num) => {        
        let subquestionsArray = []
        let optionsUrl="https://rombodigital.mx/carnot/apikicab/api/options/"+num        
        return fetch(optionsUrl)
        .then((response) => response.json())
        .then((data) => {
            if(!data.subquestions){
                setHassubquestions(false)
                setSubquestions([])
                let optionsArray:any = Object.values(data.options)            
                setOptions(optionsArray)
            }else{
                setHassubquestions(true)
                subquestionsArray=Object.values(data.subquestions)
                setSubquestions(subquestionsArray)
                let suboptionsarray = new Array()
                subquestionsArray.forEach(
                    function (item, index, arr) {
                        let suboption = []
                        suboption=Object.values(item['suboptions'])
                        suboptionsarray.push(suboption)
                    }
                )
                setSuboptions(suboptionsarray)                

            }
        })
    }
   
      
    useEffect(() => {
        fetchQuestionsData();        
    },[])
    

    const contentRef = useRef<HTMLIonContentElement | null>(null);
    const scrollToTop= () => {
        contentRef.current && contentRef.current.scrollToTop();
    };

    return (
            <IonContent ref={contentRef} fullscreen>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <Link to="/carlsson">
                                <IonButton>
                                    <img src='./assets/img/volver.png'/>
                                </IonButton>
                            </Link> 
                        </IonButtons>
                        <IonTitle>
                            <IonText>
                                <h3 className='title'>Carlsson-Dent</h3>
                            </IonText>
                        </IonTitle>
                        <IonButtons slot="end">
                                <Link to="/home1">
                                    <IonButton>
                                    <img src='./assets/img/cerrar.png'/>
                                    </IonButton>
                                </Link>
                            </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <MenuCuestionario  questionsLength={questionsLength} currentQuestion={currentQuestion}/>
                
                <IonText>
                    <h3 className='text pb2'>
                    {questions[currentQuestion]['question_text']}
                    </h3>
                </IonText>

                    {
                        hassubquestions==false
                        ?
                            <IonRadioGroup value={selected}>
                            {options.map((x, i) =>
                                <IonItem className={"answer "+getClass(x['option_id'])} key={x['option_id']} lines="none">
                                    <IonRadio value={x['option_id']} onClick={() => answer(questions[currentQuestion]['question_id'],x['option_value'],x['option_text'],x['option_id'])}></IonRadio>
                                    <IonLabel className="text-answer ion-text-wrap">{x['option_text']}</IonLabel>
                                    <p className="chip-answer">{x['option_value']}</p>
                                </IonItem>
                            )}
                            </IonRadioGroup>
                        :
                        subquestions.map((x, i) =>
                        <div>
                            <IonText>
                                <h4 className='text pb2'>
                                    {x['question_text']}
                                </h4>
                            </IonText>
                            <IonRadioGroup>
                                {
                                    suboptions.map((y, ii) =>
                                    <IonItem className="answer" key={y[ii]['option_id']} lines="none">
                                        <IonRadio value={y[ii]['option_value']} onClick={() => answerSuboptions(x['question_id'],y[ii]['option_value'],y[ii]['option_text'],x)}></IonRadio>
                                        <IonLabel className="text-answer ion-text-wrap">{y[ii]['option_text']} </IonLabel>
                                        <p className="chip-answer">{y[ii]['option_value']}</p>
                                    </IonItem>
                                    )
                                }
                            </IonRadioGroup>
                        </div>
                    )

                    }
                {currentQuestion<questionsLength-1 && hassubquestions==false &&
                    <IonButton disabled={selected==""} expand="block" size="large" className="btn-grey"  color="primary" onClick={nextQuestion}>Siguiente </IonButton>
                }
                {currentQuestion<questionsLength-1 && hassubquestions==true &&
                    <IonButton disabled={answeredSuboptionsState<subquestions.length} expand="block" size="large" className="btn-grey"  color="primary" onClick={nextQuestion}>Siguiente </IonButton>
                }
                {currentQuestion==questionsLength-1 &&
                    <IonButton disabled={selected==""} expand="block" size="large" className="btn-grey"  color="primary" onClick={sumbitAnswers}>Finalizar </IonButton>
                }
            </IonContent>
    );
  };
  
  export default Cuestionario;