import React, {useEffect} from "react";
import { Link } from 'react-router-dom';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonText, IonButtons, IonButton } from '@ionic/react';
import './Carlsson.css';
import useTegoStore from "../../states"

const Carlsson: React.FC = () => {

    function GetCarlsson(data) {
        //useTegoStore((state)=>state.updateHello)
    }

    return (
            <IonContent fullscreen className="azul">
                <IonHeader>
                    <IonToolbar>

                        <IonTitle>
                            <IonText>
                                <h3 className='title  first-title'>Carlsson-Dent</h3>
                            </IonText>
                        </IonTitle>
                        <IonButtons slot="end">
                                <Link to="/home1">
                                    <IonButton>
                                    <img src='./assets/img/cerrar.png'/>
                                    </IonButton>
                                </Link>
                            </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonText>
                    <h3 className='text-sm'><span>7 días antes</span> del inicio <br/>del tratamiento</h3>
                </IonText>
                <img src="./assets/img/llamas.gif" width="200px" className="img-center pt1"/>

                <IonText>
                    <h3 className='text-bg'><span>Instrucciones</span><br />
                    Por favor, lea las preguntas <br />y elija una opción.</h3>
                </IonText>
                <Link to="/cuestionario" className="link-btn">
                    <IonButton expand="block" size="large" className="btn">
                            Empezar
                    </IonButton>
                </Link>   
            </IonContent>
    );
};
  
export default Carlsson;