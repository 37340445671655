import React, {useState} from 'react';

const MenuSintomas: React.FC = () => {
  const [totalDays, setTotalDays] = useState(localStorage.getItem('total_days'));
  
  let dias = Number(totalDays);

  //const listItems = ['1', '2', '3', '4', '5', '6', '7']; 
  const listItems = ['7', '6', '5', '4', '3', '2', '1']; 
  const n = dias;
  const array: number[] = [];
  for (let i = 0; i < n; i++) {
    array.push(i);
  }
  const [activeItems, setActiveItems] = useState(array); // array con índices de elementos activos

    return (
      <nav>
      <ul className="number-menu-home" style={{marginTop: 0}}>
        {listItems.map((item, index) => (
          <li key={index} className={activeItems.includes(index) ? 'active' : ''}>
            {item}
          </li>
        ))}
      </ul>
    </nav>
    );
  };
  export default MenuSintomas;