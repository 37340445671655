import React from "react";
import { Link } from 'react-router-dom';
import { IonContent, IonHeader, IonTitle, IonToolbar, IonText, IonButtons, IonButton } from '@ionic/react';
import './Gerd.css';

const Gerd: React.FC = () => {
    return (
            <IonContent fullscreen>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>
                            <IonText>
                                <h3 className='title  first-title'>Calidad de Vida</h3>
                            </IonText>
                        </IonTitle>
                        <IonButtons slot="end">
                                <Link to="/home1">
                                    <IonButton>
                                    <img src='./assets/img/cerrar.png'/>
                                    </IonButton>
                                </Link>
                            </IonButtons>
                    </IonToolbar>
                </IonHeader>
                    <img src="./assets/img/llamas.gif" width="200px" className="img-center pt1"/>

                <IonText>
                    <h3 className='text-bg'><span>Instrucciones</span><br />
                    Por favor, lea los enunciados <br />
                    y califíquelos del 0 al 5.</h3>
                </IonText>
                <img src='./assets/img/instrucciones.png' className="img-center pt1"/>
                <Link to="/calidad-de-vida-sintomas" className="link-btn">
                    <IonButton expand="block" size="large" className="btn">
                            Empezar
                    </IonButton>
                </Link>   
            </IonContent>
    );
};
  
export default Gerd;