import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import { IonContent, IonHeader, IonTitle, IonToolbar, IonItem, IonLabel, IonInput, IonRow, IonCol, IonGrid, IonText, IonButton, useIonAlert, IonButtons, IonNavLink } from '@ionic/react';
import './Login.css';
import Welcome from './Welcome';
import jwt_decode from "jwt-decode";

const Login: React.FC = () => {
  const [formLogin, setFormLogin] = useState({
    email: '',
    password: ''
  });

  const [errorAlert] = useIonAlert();

  const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validar campos no vacíos
    if (!formLogin.email || !formLogin.password) {
      errorAlert({
        header: 'Error',
        message: 'Por favor, rellene todos los campos.',
        buttons: ['OK'],
      });
      return;
    }

    if (!emailRegex.test(formLogin.email)) {
      errorAlert({
        header: 'Error',
        message: 'Dirección de correo electrónico no válida, favor de verificarla.',
        buttons: ['OK'],
      });
      return;
    }

    console.log(formLogin);
    fetch('https://rombodigital.mx/carnot/apikicab/api/login', {
      method: 'POST',
      body: JSON.stringify(formLogin),
      headers: {
        'Content-Type': 'text/plain',
      }
    })
    .then(res => res.json())
    .then(response => {
      console.log(response);
      if(response.token){
        let decoded = jwt_decode(response.token);
        let decoded2 = JSON.stringify(decoded);
        let decoded3 = JSON.parse(decoded2);
        console.log(decoded3.user_id);
        
        localStorage.setItem('authId', decoded3.user_id);
        window.location.href = '/home1';
      } else {
        console.log("fallo");
        errorAlert({
          header: 'Error',
          message: 'Verifique sus correo y contraseña',
          buttons: ['OK'],
        });
      }
    })
    .catch(error => {
      console.error(error);
    });
      
  };

  return (
    <IonContent className='blackBG'>
      <IonHeader>
        <IonToolbar className='toolbarTrans'>
          <IonButtons slot="start" className='backBtnLogin'>
            <Link to="/welcome">
                <IonButton>
                    <img src='./assets/img/volver.png' alt=''/>
                </IonButton>
            </Link> 
          </IonButtons>
        </IonToolbar>
          <IonTitle className='ion-text-center'>
            <img src='./assets/carnot_logo.png' className='imgLogin' alt=""/>
          </IonTitle>
      </IonHeader>
        <IonText color="primary">
            <h1 className='textWelcome'>Inicio de sesión</h1>
        </IonText>
      <IonGrid>
        <IonRow>
        <IonCol size-md="2"></IonCol>
          <IonCol size-xs="12" size-md="8">
            <form  onSubmit={handleSubmit}>

              <IonItem className='itemInicioSesion' lines="none">
                <IonLabel position="stacked" className='labelInicioSesion'>Correo electrónico</IonLabel>
                <IonInput 
                  type="email" 
                  placeholder="Ingrese correo electrónico" 
                  className="inputInicioSesion"
                  id='email'
                  name='email'
                  value={formLogin.email}
                  onBlur={e => setFormLogin({ ...formLogin, [e.target.name]: e.target.value })} 
                  onChange={(e) => setFormLogin({ ...formLogin, email: (e.target as HTMLInputElement).value })}
                />
              </IonItem>

              <IonItem className='itemInicioSesion' lines="none">
                <IonLabel position="stacked" className='labelInicioSesion'>Contraseña</IonLabel>
                <IonInput 
                  type="password" 
                  placeholder="Ingrese contraseña" 
                  className="inputInicioSesion" 
                  id='password'
                  name='password'
                  value={formLogin.password}
                  onBlur={e => setFormLogin({ ...formLogin, [e.target.name]: e.target.value })} 
                />
              </IonItem>

              <div className='navForgot displayNone'>
                <IonNavLink routerDirection="forward" component={() => <Welcome />}>
                  <IonText className='textForgot'>Olvidé mi contraseña</IonText>
                </IonNavLink>
              </div>


              <IonButton expand="block" className='submitBtnInicioSesion' type='submit'>Iniciar sesión</IonButton>
            </form>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonContent>
  );
};

export default Login;
