import useTegoStore from "../../../states"

const MenuCuestionario = ({questionsLength=0, currentQuestion=0 }): JSX.Element => { 
//const MenuCuestionario: React.FC = () => {
  //let mundo = useTegoStore((state)=>state.hello)

  function getClass(num:Number):string {
    if(num==currentQuestion){
      return "activo"
    } else if(num<currentQuestion){
      return "completada"
    }else{
      return "nocompletada"
    }
  }
  //className={() => getClass()}

    return (
      <nav>
        <ul className="number-menu">
        {[...Array(questionsLength)].map((x, i) =>
          <li className={getClass(i)} key={"key_"+i}>{i+1}</li>
        )}
        </ul>
      </nav>
    );
  };
  export default MenuCuestionario;