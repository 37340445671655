import React, {useEffect, useState, useContext } from "react";

import { Link,useLocation  } from 'react-router-dom';
import { IonContent, IonPage, IonText, IonButton } from '@ionic/react';
import './Gerd.css';

const Agradecimiento: React.FC = () => {
    const location = useLocation();
    const [authId, setAuthId] = useState(localStorage.getItem('authId'));
    const [submitDate, setSubmitDate] = useState("");
    const [show_carlson, setShowCarlson] = useState(localStorage.getItem('show_carlson'));
    const [totalDays, setTotalDays] = useState(localStorage.getItem('total_days'));
    const [totalResponsesCarlson, setTotalResponsesCarlson] = useState(localStorage.getItem('total_carlson'));
    let reload = 1;

    let puntuacion:any= location.state

    let visible = '';

    function sumbitAnswers() {
        let result={
            "user_id":authId,
            "questionnaire_id": "2"
        }
        fetch('https://s229333.gridserver.com/carnot/apikicab/api/results', {
            method: 'POST',
            body: JSON.stringify(result),
            headers: {
                'Content-Type': 'text/plain',
        }
        })
            .then(res => res.json()) 
            .then(response => {
                if(String(response.message).indexOf('Success')>-1){
                    console.log(response)
                    console.log(response['created_at'])
                    setSubmitDate(response['created_at'])
                }
        })
            .catch(error => {
            console.error(error);
        })
    }

    useEffect(() => {
        sumbitAnswers();        
    },[])

    //valida si se muestran los botones de los cuestionarios
    if((Number(totalDays) <= 7 && Number(totalResponsesCarlson) === 0)){
        localStorage.setItem('show_carlson','true');
        visible = 'true';
        //console.log('<7');
    } else if((Number(totalDays) >= 8 && Number(totalResponsesCarlson) == 1) && (Number(totalDays) < 14 && Number(totalResponsesCarlson) === 1)){
        localStorage.setItem('show_carlson','true');
        visible = 'true';
        //console.log('>=8');
    } else if((Number(totalDays) >= 14 && Number(totalResponsesCarlson) === 2) && (Number(totalDays) < 35 && Number(totalResponsesCarlson) === 2)){
        localStorage.setItem('show_carlson','true');
        visible = 'true';
        //console.log('>=14');
    } else if((Number(totalDays) >= 35 && Number(totalResponsesCarlson) === 3) && (Number(totalDays) < 42 && Number(totalResponsesCarlson) === 3)){
        localStorage.setItem('show_carlson','true');
        visible = 'true';
        //console.log('>=35');
    } else if((Number(totalDays) >= 42 && Number(totalResponsesCarlson) === 4) && (Number(totalDays) < 49 && Number(totalResponsesCarlson) === 4)){
        localStorage.setItem('show_carlson','true');
        visible = 'true';
        //console.log('>=42');
    } else {
        localStorage.setItem('show_carlson','');
        visible = '';
    }

    useEffect(() => {
        localStorage.setItem('show_carlson', visible);
      }, [show_carlson]);
    
      console.log(show_carlson);
      console.log(totalDays);
      console.log(totalResponsesCarlson);
    

    return (
            <IonContent fullscreen>
                <IonText>
                    <h3 className='text title-puntuacion'>¡Gracias!</h3>
                </IonText>
                <img src="./assets/img/check.gif" width="200px" className="img-center pt1"/>

                <IonText>
                    <h3 className='text-sm'><span>Sus respuestas han sido guardadas exitosamente.</span><br /></h3>
                    <h3 className="text-sm txt-points">Puntaje total:</h3>
                    <h3 className="points">{puntuacion['total']}</h3>
                    <h3 className="text-sm txt-lite">Fecha de registro:<br/> {submitDate}</h3>
                    <h3 className="text pl2 pr2 pt2">
                    En los próximos días recibirá recordatorios para continuar con los registros correspondientes.
                    </h3>
                </IonText>
                {localStorage.getItem('show_carlson') === 'true' &&
                    <>
                        <Link to="/carlsson" className="link-btn">
                            <IonButton expand="block" size="large" className="btn-go-calidad">
                                Cuestionario Carlsson-Dent
                            </IonButton>
                        </Link> 
                    </>
                }
                <Link to="./home1" className="link-btn">
                    <IonButton expand="block" size="large" className="btn-go-calidad">
                        Finalizar
                    </IonButton>
                </Link>  
            </IonContent>
    );
  };
  
  export default Agradecimiento
;