import React, {useEffect, useState, useRef } from "react";
import { Link, useHistory } from 'react-router-dom';
import {IonImg, IonRow, IonCol, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonText, IonButtons, IonButton, IonRadio, IonRadioGroup, IonItem, IonLabel, useIonAlert  } from '@ionic/react';
import './Sintomas.css';
import MenuSintomas from "../../components/Sintomas/MenuSintomas";

const HomeSintomas: React.FC = () => {
    
    return (
            <IonContent fullscreen className="sintomas">
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>
                            <IonText>
                                <h3 className='title'>Sus registros</h3>
                            </IonText>
                        </IonTitle>
                            <IonButtons slot="end">
                                <Link to="/home1">
                                    <IonButton>
                                    <img src='./assets/img/cerrar.png'/>
                                    </IonButton>
                                </Link>
                            </IonButtons>
                    </IonToolbar>
                </IonHeader>

                <MenuSintomas/>
                
                <IonText>
                    <h3 className='symptoms-day displayNone'>
                        <b>Día 1</b> <br />
                        (01/ene/2023)
                    </h3>
                </IonText>
                
                <IonRow className='rowTextCenter'>
                    <IonCol size="4" size-xs="4" size-md="4">
                    <Link to="/agruras">
                        <IonImg src="./assets/agruras.svg" alt="" className='imgSintomasHome1'></IonImg>
                        <IonText className='textSintomasHome1'>Agruras</IonText>
                    </Link>
                    </IonCol>
                    <IonCol size="4" size-xs="4" size-md="4">
                    <Link to="/regurgitacion">
                        <IonImg src="./assets/regurgitacion.svg" alt="" className='imgSintomasHome1'></IonImg>
                        <IonText className='textSintomasHome1'>Regurgitación</IonText>
                    </Link>
                    </IonCol>
                    <IonCol size="4" size-xs="4" size-md="4">
                    <Link to="/sin-sintomas">
                        <IonImg src="./assets/sin-sintomas.svg" alt="" className='imgSintomasHome1'></IonImg>
                        <IonText className='textSintomasHome1'>Sin síntomas</IonText>
                    </Link>
                    </IonCol>
                </IonRow>

                <IonText className="displayNone">
                    <h3 className="symptoms-alert">
                        No hizo registro de síntomas este día, toque los botones de arriba para agregar un registro.
                    </h3>
                </IonText> 

            </IonContent>
    );
  };
  
  export default HomeSintomas;