import React from "react";

import { Link } from 'react-router-dom';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonText, IonButtons, IonButton, /* IonRadio, IonRadioGroup, */ IonCheckbox, IonItem,IonLabel } from '@ionic/react';
import './Gerd.css';
import MenuCalidad from "../../components/Gerd/MenuCalidad";

const Gerd13: React.FC = () => {
    console.log("Cuestionario Pag.1");
    return (
            <IonContent fullscreen>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <Link to="/calidad-de-vida-p12">
                                <IonButton>
                                    <img src='./assets/img/volver.png'/>
                                </IonButton>
                            </Link> 
                        </IonButtons>
                        <IonTitle>
                            <IonText>
                                <h3 className='title'>Calidad de Vida</h3>
                            </IonText>
                        </IonTitle>
                        <IonButtons slot="end">
                            <IonButton>
                            <img src='./assets/img/cerrar.png'/>
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <MenuCalidad/>
                <IonText>
                    <h3 className='text pb2'>Elija cualquiera de los <br />
                    medicamentos que haya <br />
                    tomado en el pasado o <br />que esté tomando actualmente:</h3>
                </IonText>
                <div>
                    <div className="answer-multiple satisfaction-answer">
                        <IonItem>
                            <IonCheckbox slot="start"></IonCheckbox>
                            <IonLabel>Nexium Mups</IonLabel>
                        </IonItem>
                        <IonItem>
                            <IonCheckbox slot="start"></IonCheckbox>
                            <IonLabel>Dexivant</IonLabel>
                        </IonItem>
                        <IonItem>
                            <IonCheckbox slot="start"></IonCheckbox>
                            <IonLabel>Ulsen</IonLabel>
                        </IonItem>
                        <IonItem>
                            <IonCheckbox slot="start"></IonCheckbox>
                            <IonLabel>Norutec</IonLabel>
                        </IonItem>
                        <IonItem>
                            <IonCheckbox slot="start"></IonCheckbox>
                            <IonLabel>Gamo</IonLabel>
                        </IonItem>
                        <IonItem>
                            <IonCheckbox slot="start"></IonCheckbox>
                            <IonLabel>Amably</IonLabel>
                        </IonItem>
                        <IonItem>
                            <IonCheckbox slot="start"></IonCheckbox>
                            <IonLabel>Zoltum</IonLabel>
                        </IonItem>
                        <IonItem>
                            <IonCheckbox slot="start"></IonCheckbox>
                            <IonLabel>Pantozol</IonLabel>
                        </IonItem>
                        <IonItem>
                            <IonCheckbox slot="start"></IonCheckbox>
                            <IonLabel>Omeprazol genérico</IonLabel>
                        </IonItem>
                        <IonItem>
                            <IonCheckbox slot="start"></IonCheckbox>
                            <IonLabel>Otro</IonLabel>
                        </IonItem>
                    </div>
                </div>
                <Link to="/agradecimiento" className="link-btn">
                    <IonButton expand="block" size="large" className="btn-grey"  color="primary">Siguiente</IonButton>
                </Link>
            </IonContent>
    );
  };
  
  export default Gerd13;