import { Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  /* IonIcon, */
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
/* import { ellipse, square, triangle } from 'ionicons/icons'; */
import Login from './pages/home/Login';
import Welcome from './pages/home/Welcome';
import Recuperar from './pages/home/Recuperar';
import Recuperar2 from './pages/home/Recuperar2';
import SaveNewPass from './pages/home/SaveNewPass';
import SavePassSuccess from './pages/home/SavePassSuccess';
import Register from './pages/home/Register';
import Home1 from './pages/home/Home1';
import Carlsson from './pages/Carlsson/Carlsson';
import Cuestionario from './pages/Carlsson/Cuestionario';
import Puntuacion from './pages/Carlsson/Puntuacion';
import Gerd from './pages/Gerd/Gerd';
import SintomasCDV from './pages/Gerd/GerdSintomas';
import Gerd12 from './pages/Gerd/Gerd12';
import Gerd13 from './pages/Gerd/Gerd13';
import Agradecimiento from './pages/Gerd/tnks';
import HomeSintomas from './pages/Sintomas/HomeSintomas';
import Agruras from './pages/Sintomas/Agruras';
import Regurgitacion from './pages/Sintomas/Regurgitacion';
import Sinsintomas from './pages/Sintomas/SinSintomas';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

setupIonicReact();



const App: React.FC = () => (
  
  <IonApp>
    <IonReactRouter>
      <IonTabs>
        <IonRouterOutlet>
          <Route exact path="/"><Redirect to="/welcome" /></Route>
          <Route path="/welcome"><Welcome /></Route>
          <Route exact path="/register"><Register /></Route>
          <Route exact path="/login"><Login /></Route>

          {/* LIMON */}
          <Route exact path="/carlsson"><Carlsson /></Route>
          <Route exact path="/cuestionario"><Cuestionario /></Route>
          <Route exact path="/puntuacion"><Puntuacion /></Route>
          <Route exact path="/calidad-de-vida"><Gerd /></Route>
          <Route exact path="/calidad-de-vida-sintomas"><SintomasCDV/></Route>
          <Route exact path="/calidad-de-vida-p12"><Gerd12/></Route>
          <Route exact path="/calidad-de-vida-p13"><Gerd13/></Route>
          <Route exact path="/agradecimiento"><Agradecimiento/></Route>
          <Route exact path="/sintomas"><HomeSintomas/></Route>
          <Route exact path="/agruras"><Agruras/></Route>
          <Route exact path="/regurgitacion"><Regurgitacion/></Route>
          <Route exact path="/sin-sintomas"><Sinsintomas/></Route>

          {/* LIMON */}

          {/* RAFA */}
          <Route path="/welcome"><Welcome /></Route>
          <Route exact path="/login"><Login /></Route>
          <Route exact path="/register"><Register /></Route>
          <Route path="/recuperar"><Recuperar /></Route>
          <Route path="/recuperar2"><Recuperar2 /></Route>
          <Route path="/savepass"><SaveNewPass /></Route>
          <Route path="/savepasssuccess"><SavePassSuccess /></Route>
          <Route path="/home1"><Home1 /></Route>
          <Route path="/agruras"><Agruras /></Route>
          <Route path="/regurgitacion"><Regurgitacion /></Route>
          {/* RAFA */}


        </IonRouterOutlet>
        <IonTabBar slot="bottom" className='displayNone'>
          <IonTabButton tab="tab4" href="/welcome">
            <IonLabel>Welcome</IonLabel>
          </IonTabButton>
          <IonTabButton tab="register" href="/register">
            <IonLabel>Register</IonLabel>
          </IonTabButton>
          <IonTabButton tab="login" href="/login">
            <IonLabel>Login</IonLabel>
          </IonTabButton>

          {/* LIMON */}
          <IonTabButton tab="carlsson" href="/carlsson">
            <IonLabel>Carlsson</IonLabel>
          </IonTabButton>
          <IonTabButton tab="calidaddevida" href="/calidad-de-vida">
            <IonLabel>Gerd</IonLabel>
          </IonTabButton>
          <IonTabButton tab="sintomas" href="/sintomas">
            <IonLabel>Sintomas</IonLabel>
          </IonTabButton>
          {/* LIMON */}


        </IonTabBar>
      </IonTabs>
    </IonReactRouter>

  </IonApp>
);

export default App;