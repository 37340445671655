import React from 'react';
import { Link } from 'react-router-dom';
import { IonImg, IonRow, IonCol, IonText, IonCard, IonCardHeader, IonCardContent  } from '@ionic/react';
import ListSintomas from "./ListSintomas";

const CardSintomas: React.FC = () => {
  return (
    <>
    <IonCard className='cardHome1'>
      <IonCardHeader className='HeaderSintomasHome1'>
      <IonRow className='rowTextCenter'>
        <IonCol size="4" size-xs="4" size-md="4">
          <Link to="/agruras">
            <IonImg src="./assets/agruras.svg" alt="" className='imgSintomasHome1'></IonImg>
            <IonText className='textSintomasHome1'>Agruras</IonText>
          </Link>
        </IonCol>
        <IonCol size="4" size-xs="4" size-md="4">
          <Link to="/regurgitacion">
            <IonImg src="./assets/regurgitacion.svg" alt="" className='imgSintomasHome1'></IonImg>
            <IonText className='textSintomasHome1'>Regurgitación</IonText>
          </Link>
        </IonCol>
        <IonCol size="4" size-xs="4" size-md="4">
          <Link to="/sin-sintomas">
            <IonImg src="./assets/sin-sintomas.svg" alt="" className='imgSintomasHome1'></IonImg>
            <IonText className='textSintomasHome1'>Sin síntomas</IonText>
          </Link>
        </IonCol>
      </IonRow>
      </IonCardHeader>
      <IonCardContent className='displayNone'>
        <p className='textDescHome1'>
          Seleccione un día para editar sus registros:
        </p>
        
      </IonCardContent>
      <ListSintomas />
    </IonCard>
    </>
  );
};
export default CardSintomas;