import React from 'react';
import { IonContent, IonHeader, IonTitle, IonToolbar, IonItem, IonLabel, IonInput, IonRow, IonCol, IonGrid, IonText, IonButton, IonNote, IonButtons } from '@ionic/react';
import './Recuperar.css';

const Recuperar: React.FC = () => {
  return (
    <IonContent className='blackBG'>
      <IonHeader>
        <IonToolbar className='toolbarTrans'>
          <IonButtons slot="end" className='btnCloseRecuperar'>
              <IonButton>
                <img src='./assets/img/cerrar.png' alt=''/>
              </IonButton>
          </IonButtons>
        </IonToolbar>
        <IonToolbar className='toolbarTrans'>
          <IonTitle className='ion-text-center'>
            <img src='./assets/carnot_logo.png' className='imgRecuperar' alt=""/>
          </IonTitle>
        </IonToolbar>
        <IonText color="primary">
            <h1 className='textRecuperaTitle'>Recupere su contraseña</h1>
        </IonText>
      </IonHeader>
      <IonGrid>
        <IonRow>
          <IonCol size-md="2"></IonCol>
          <IonCol size-xs="12" size-md="8">
            <p className='textRecuperar'>
            Escriba la dirección de correo electrónico con la que se registró y le enviaremos un código para restablecer su contraseña.
            </p>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size-md="2"></IonCol>
          <IonCol size-xs="12" size-md="8">
            <form action="">

              <IonItem className='itemRecuperar' no-lines>
                <IonLabel position="stacked" className='labelRecuperar'>Correo electrónico</IonLabel>
                <IonInput type="email" placeholder="Ingrese correo electrónico" className="inputRecuperar" autofocus></IonInput>
                <IonNote slot="error">Invalid email</IonNote>
              </IonItem>

              <IonButton expand="block" className='submitBtnInicioSesion' type='submit'>Enviar</IonButton>
            </form>
          </IonCol>
          <IonCol size="4"></IonCol>
        </IonRow>
      </IonGrid>
    </IonContent>
  );
};

export default Recuperar;
