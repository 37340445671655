import React, {useEffect, useState, useRef } from "react";
import { Link, useHistory } from 'react-router-dom';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonText, IonButtons, IonButton, IonRadio, IonRadioGroup, IonItem, IonLabel, useIonAlert, IonDatetime, IonDatetimeButton, IonModal  } from '@ionic/react';
import moment from 'moment';
import './Sintomas.css';

const Agruras: React.FC = () => {
    const [showdate, setShowdate] = useState(false);
    const [syntomdate, setSyntomdate] = useState(moment().format())
    const datetime = useRef<null | HTMLIonDatetimeElement>(null)
    const [authId, setAuthId] = useState(localStorage.getItem('authId'))
    const history = useHistory()
    const [syntomAlert] = useIonAlert();

    function backHistory(){
        history.goBack()
    }

    function toggleShowdate() {        
        setShowdate(!showdate)
    }
    function setDatefrompicker(e) {
        setSyntomdate(e.detail.value)
    }
    function sumbitSyntom() {
        let dateY=syntomdate.split("T")[0]
        let dateT=syntomdate.split("T")[1].split(".")[0]
        
        let newmoment=moment(syntomdate)
        
        let syntomObject={
            "symptom_id": "1",
            "user_id":authId,
            "created_at": newmoment.format('YYYY-MM-DD h:mm:ss')
        }

        fetch('https://rombodigital.mx/carnot/apikicab/api/symptoms', {
            method: 'POST',
            body: JSON.stringify(syntomObject),
            headers: {
                'Content-Type': 'text/plain',
        }
        })
            .then(res => res.json()) 
            .then(response => {
            if(response.message === 'Success'){
                if(localStorage.getItem('first_syntomp_day')==''){
                    let fecha = moment();
                    let fechaMoment = fecha.format('YYYY-MM-DD');
                    localStorage.setItem('first_syntomp_day', fechaMoment);
                    console.log(fechaMoment);
                }
                syntomAlert({
                    header: 'Gracias',
                    message: 'Su registro fue recibido',
                    buttons: ['OK'],
                    onDidDismiss: ()=>{history.push("/sintomas") }
                  });
            }
        })
            .catch(error => {
            console.error(error);
        })
    }
    return (
            <IonContent fullscreen className="sintomas">
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>
                            <IonText>
                                <h3 className='title'>Agruras</h3>
                            </IonText>
                        </IonTitle>
                            <IonButtons slot="end">
                                <Link to="/home1">
                                    <IonButton>
                                    <img src='./assets/img/cerrar.png'/>
                                    </IonButton>
                                </Link>
                            </IonButtons>
                    </IonToolbar>
                </IonHeader>
                
                <img src='./assets/img/agruras-lt.png' className="img-symptoms"/>

                <IonText>
                    <h3 className="symptoms-text">
                        ¿Tuvo este síntoma hoy?
                    </h3>
                </IonText>
                    <IonButton expand="block" size="large" className="btn-symptoms" onClick={toggleShowdate}>
                            Sí
                    </IonButton>
                {showdate==true &&
                    <div>
                        <h3 className="symptoms-text">Registre la hora del<br/> síntoma:</h3>
                        <IonDatetimeButton datetime="datetime" className="date" color="danger"></IonDatetimeButton>      
                        <IonModal keepContentsMounted={true}>
                            <IonDatetime id="datetime"
                                presentation="time" 
                                showDefaultButtons={true}
                                doneText="Aceptar"
                                cancelText="Cancelar"
                                ref={datetime} 
                                value={syntomdate}
                                onIonChange={setDatefrompicker}
                                >
                                </IonDatetime>
                        </IonModal>
                    </div>
                }

                <Link to="/sintomas" className="link-btn">
                    <IonButton expand="block" size="large" className="btn-symptoms">
                            No
                    </IonButton>
                </Link>   
                <IonButton disabled={!showdate} expand="block" size="large" className="btn-symptoms-grey" color="secondary" onClick={sumbitSyntom}>
                    Guardar 
                </IonButton>   

            </IonContent>
    );
  };
  
  export default Agruras;