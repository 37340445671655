import React from "react";

import { Link } from 'react-router-dom';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonText, IonButtons, IonButton, IonRadio, IonRadioGroup } from '@ionic/react';
import './Gerd.css';
import MenuCalidad from "../../components/Gerd/MenuCalidad";

const Gerd12: React.FC = () => {
    console.log("Cuestionario Pag.1");
    return (
            <IonContent fullscreen>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <Link to="/calidad-de-vida-p11">
                                <IonButton>
                                    <img src='./assets/img/volver.png'/>
                                </IonButton>
                            </Link> 
                        </IonButtons>
                        <IonTitle>
                            <IonText>
                                <h3 className='title'>Calidad de Vida</h3>
                            </IonText>
                        </IonTitle>
                        <IonButtons slot="end">
                            <IonButton>
                            <img src='./assets/img/cerrar.png'/>
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <MenuCalidad/>
                <IonText>
                    <h3 className='text pb2'>¿Toma actualmente algún <br />
                    medicamento para la acidez <br />
                    o la enfermedad de reflujo <br />gastroesofágico (ERGE)?</h3>
                </IonText>
                <div>
                    <div className="answer-multiple satisfaction-answer">
                        <IonRadioGroup value="custom-checked">
                            <IonRadio value="si"></IonRadio><label className="text-answer">Si</label> <br /><br />
                            <IonRadio value="no"></IonRadio><label className="text-answer">No</label>  
                        </IonRadioGroup>
                    </div>
                </div>
                <Link to="/calidad-de-vida-p13" className="link-btn">
                    <IonButton expand="block" size="large" className="btn-grey"  color="primary">Siguiente</IonButton>
                </Link>
            </IonContent>
    );
  };
  
  export default Gerd12;