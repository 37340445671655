import React, {useEffect, useState, useRef } from "react";

import { Link, useHistory } from 'react-router-dom';
import { IonContent, IonHeader, IonTitle, IonToolbar, IonText, IonButtons, IonButton,  IonRadio, IonRadioGroup, IonItem, IonLabel, useIonAlert  } from '@ionic/react';
import './Gerd.css';
/* import MenuCalidad from "../../components/Gerd/MenuCalidad"; */
import MenuCalificacion from "../../components/Gerd/MenuCalificacion";

import MenuCuestionario from "../../components/Carlsson/Cuestionario/MenuCuestionario";
import { log } from "console";

let answersObject:object = {}


const SintomasCDV: React.FC = () => {
    const history = useHistory()
    const [questions, setQuestions] = useState([{"question_id": "0","question_text": "","question_order": "0"}]);
    const [questionsLength, setQuestionsLength] = useState(0);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [currentQuestionID, setCurrentQuestionID] = useState(0);
    const [options, setOptions] = useState([{"option_id": "0","option_value": "0","option_text": ""}]);
    const [selected, setSelected] = useState("");
    const [authId, setAuthId] = useState(localStorage.getItem('authId'));
    const [answers, setAnswers] = useState({});
    const [total, setTotal] = useState({'total':0});

    const [stepNumberC, setStepNumberC] = useState(localStorage.getItem('stepNumberC'));
    console.log("paso: ",stepNumberC);
    
    if(stepNumberC==null){
        localStorage.setItem('stepNumberC','0')
    }

    function nextQuestion() {
        setSelected("")
        let nextQuestionIndex=Number(currentQuestion)+1
        let nextQuestionID= Number(questions[nextQuestionIndex]['question_id'])
        setCurrentQuestion(nextQuestionIndex)
        fetchOptionsData(nextQuestionID)
        setCurrentQuestionID(nextQuestionID)
        scrollToTop()
    }


    function sumbitAnswers() {
        let totalsum=0
        setAnswers(answersObject)
        let arrAnswers=Object.values(answersObject)
        arrAnswers.forEach((item, index, arr)=>{
            totalsum+=Number(item['value'])
            setTotal({'total':totalsum})
        })
        
        
        let step= Number(stepNumberC)+1
        localStorage.setItem('stepNumberC',step.toString());
        localStorage.setItem('total_calidad',step.toString());
        console.log(localStorage.getItem('total_calidad'));

        history.push({pathname:"/agradecimiento",
            state:{'total':totalsum,'step':localStorage.getItem('stepNumber')},
        }) 
    }
    function answer(id,value,text,option){
        setSelected(option)

        let answerObject={}
        answerObject={
            "question_id": Number(id),
            "user_id":authId,
            "value" : value,
            "text" : text
        }
        console.log(answerObject);
        
        
        answersObject[currentQuestionID]=answerObject
        fetch('https://rombodigital.mx/carnot/apikicab/api/answers', {
            method: 'POST',
            body: JSON.stringify(answerObject),
            headers: {
                'Content-Type': 'text/plain',
        }
        })
            .then(res => res.text()) 
            .then(response => {
        })
            .catch(error => {
            console.error(error);
        })
    }
    const fetchQuestionsData = () => {
        return fetch("https://rombodigital.mx/carnot/apikicab/api/questions/2")
        .then((response) => response.json())
        .then((data) => {         
            //console.log(data);
            setQuestionsLength(Object.keys(data.questions).length)
            let questionsArray:any = Object.values(data.questions)            
            setQuestions(questionsArray)
            let questionID = questionsArray[0]['question_id']
            setCurrentQuestionID(questionID)
            fetchOptionsData(questionID)
        })
    }
    const fetchOptionsData = (num) => {        
        let optionsUrl="https://rombodigital.mx/carnot/apikicab/api/options/"+num        
        return fetch(optionsUrl)
        .then((response) => response.json())
        .then((data) => {
            //console.log(data);
            let optionsArray:any = Object.values(data.options)
            setOptions(optionsArray)

        })
    }

    useEffect(() => {
        fetchQuestionsData();        
    },[])
    

    const contentRef = useRef<HTMLIonContentElement | null>(null);
    const scrollToTop= () => {
        contentRef.current && contentRef.current.scrollToTop();
    };
   

    /* console.log("Cuestionario Pag.1"); */
    return (
            <IonContent ref={contentRef} fullscreen>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <Link to="/calidad-de-vida">
                                <IonButton>
                                    <img src='./assets/img/volver.png'/>
                                </IonButton>
                            </Link> 
                        </IonButtons>
                        <IonTitle>
                            <IonText>
                                <h3 className='title'>Calidad de Vida</h3>
                            </IonText>
                        </IonTitle>
                        <IonButtons slot="end">
                                <Link to="/home1">
                                    <IonButton>
                                    <img src='./assets/img/cerrar.png'/>
                                    </IonButton>
                                </Link>
                            </IonButtons>
                    </IonToolbar>
                </IonHeader>
                {/* <MenuCalidad/> */}
                <MenuCuestionario  questionsLength={questionsLength} currentQuestion={currentQuestion}/>
                <IonText>
                    <h3 className='text pb1'>{questions[currentQuestion]['question_text']}</h3>
                </IonText>
                {currentQuestion<10 &&
                    <div>
                        <IonText>
                            <p className="text-sm">
                            Seleccione el número con el que califica sus síntomas:
                            </p>
                        </IonText>
                        <nav>
                            <ul className="menu-calificacion">
                                {options.map((x, i) =>
                                    <li onClick={() => answer(currentQuestionID,x['option_value'],x['option_text'],x['option_value'])}>{x['option_value']}</li>
                                    )}
                            </ul>
                        </nav>
                    </div>
                }
                {currentQuestion>=10 &&
                    <div>
                            <IonRadioGroup value={selected}>
                            {options.map((x, i) =>
                                <IonItem className="answer" key={x['option_id']} lines="none">
                                    <IonRadio value={x['option_id']} onClick={() => answer(questions[currentQuestion]['question_id'],x['option_value'],x['option_text'],x['option_id'])}></IonRadio>
                                    <IonLabel className="text-answer ion-text-wrap">{x['option_text']}</IonLabel>
                                    <p className="chip-answer">{x['option_value']}</p>
                                </IonItem>
                            )}
                            </IonRadioGroup>
                    </div>
                }


                {currentQuestion<questionsLength-1 &&
                    <IonButton disabled={selected==""} expand="block" size="large" className="btn-grey"  color="primary" onClick={nextQuestion}>Siguiente </IonButton>
                }
                {currentQuestion==questionsLength-1 &&
                    <IonButton disabled={selected==""} expand="block" size="large" className="btn-grey"  color="primary" onClick={sumbitAnswers}>Finalizar </IonButton>
                }      
                {currentQuestion<10 &&
                    <img src='./assets/img/opciones-calidad.png' className="img-center"/>
                }
                </IonContent>
    );
  };
  
  export default SintomasCDV;