import React, { useState, useRef  } from 'react';
import { Link } from 'react-router-dom';
import {IonModal, IonContent, IonHeader, IonTitle, IonToolbar, IonItem, IonLabel, IonInput, IonRow, IonCol, IonGrid, IonText, IonButton, IonNote, useIonAlert, IonCheckbox, IonButtons } from '@ionic/react';
import './Register.css';

const Register = () => {
  const [formData, setFormData] = useState({
    name: '',
    lastname: '',
    phone: '',
    email: '',
    password: '',
    doctor_code: '',
    terms : ''
  });

  const [errorAlert] = useIonAlert();
  const [isChecked, setIsChecked] = useState(false);

  const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  const textRegex = /^[a-zA-Z\s]+$/;
  const numberRegex = /^[0-9]+$/;

  const handleCheckboxChange = (event: CustomEvent) => {
    const isChecked = (event.target as HTMLInputElement).checked;
    setIsChecked(isChecked);
    if(isChecked){
      setFormData({
        ...formData,
        terms: '1'
      });
    } else {
      setFormData({
        ...formData,
        terms: ''
      });
    }
    //console.log(`El checkbox está ${isChecked ? 'seleccionado' : 'deseleccionado'}`);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validar campos no vacíos
    if (!formData.name || !formData.lastname || !formData.phone || !formData.email
         || !formData.password || !formData.doctor_code) {
      errorAlert({
        header: 'Error',
        message: 'Por favor, rellene todos los campos.',
        buttons: ['OK'],
      });
      return;
    }

    if (!formData.terms) {
      errorAlert({
        header: 'Error',
        message: 'Debe aceptar los términos y condiciones para el registro.',
        buttons: ['OK'],
      });
      return;
    }

    if (!textRegex.test(formData.name) || !textRegex.test(formData.lastname)) {
      errorAlert({
        header: 'Error',
        message: 'El nombre y apellido solo pueden contener letras sin números.',
        buttons: ['OK'],
      });
      return;
    }

    if (formData.phone.length !== 10) {
      errorAlert({
        header: 'Error',
        message: 'El teléfono debe ser a 10 digítos.',
        buttons: ['OK'],
      });
      return;
    }

    if (formData.doctor_code.length !== 10) {
      errorAlert({
        header: 'Error',
        message: 'El código debe ser de 10 digítos.',
        buttons: ['OK'],
      });
      return;
    }

    if (!emailRegex.test(formData.email)) {
      errorAlert({
        header: 'Error',
        message: 'Dirección de correo electrónico no válida, favor de verificarla.',
        buttons: ['OK'],
      });
      return;
    }

    if (!numberRegex.test(formData.phone)) {
      errorAlert({
        header: 'Error',
        message: 'El teléfono solo puede contener números.',
        buttons: ['OK'],
      });
      return;
    }

    if (!numberRegex.test(formData.doctor_code)) {
      errorAlert({
        header: 'Error',
        message: 'El código solo puede contener números.',
        buttons: ['OK'],
      });
      return;
    }

    console.log(formData);
    fetch('https://rombodigital.mx/carnot/apikicab/api/users', {
      method: 'POST',
      body: JSON.stringify(formData),
      headers: {
        'Content-Type': 'text/plain',
      }
    })
    .then(res => res.json())
    .then(response => {
      let msj = response.message;
      if(msj === 'Success'){
        window.location.href = '/login';
      }
    })
    .catch(error => {
      console.error(error);
    });
      
  };
  const modal = useRef<HTMLIonModalElement>(null);

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleHide = () => setShow(false);
  

  return (
    <IonContent className='blackBG'>
      <IonHeader>
      <IonToolbar className='toolbarTrans'>
          <IonButtons slot="start" className='backBtnLogin'>
            <Link to="/welcome">
                <IonButton>
                    <img src='./assets/img/volver.png' alt=''/>
                </IonButton>
            </Link> 
          </IonButtons>
        </IonToolbar>
          <IonTitle className='ion-text-center'>
            <img src='./assets/carnot_logo.png' className='imgRegister' alt=""/>
          </IonTitle>
      </IonHeader>
        <IonText color="primary">
            <h1 className='textLogin'>Para ingresar, complete los siguientes campos.</h1>
        </IonText>
      <IonGrid>
        <IonRow>
          <IonCol size-md="2"></IonCol>
          <IonCol size-xs="12" size-md="8">
            <form onSubmit={handleSubmit}>

              <IonItem className='itemRegister'  lines="none">
                <IonLabel position="stacked" className='labelRegister'>Nombre</IonLabel>
                <IonInput 
                  type="text" 
                  placeholder="Ingrese nombre" 
                  className="inputRegister" 
                  id='name' 
                  name='name' 
                  value={formData.name}
                  onBlur={e => setFormData({ ...formData, [e.target.name]: e.target.value })} 
                  required
                />
              </IonItem>

              <IonItem className='itemRegister'  lines="none">
                <IonLabel position="stacked" className='labelRegister'>Apellidos</IonLabel>
                <IonInput 
                  type="text" 
                  placeholder="Ingrese apellidos" 
                  className="inputRegister" 
                  id='lastname' 
                  name='lastname' 
                  value={formData.lastname} 
                  onBlur={e => setFormData({ ...formData, [e.target.name]: e.target.value })} 
                  required
                />
              </IonItem>

              <IonItem className='itemRegister'  lines="none">
                <IonLabel position="stacked" className='labelRegister'>Correo electrónico</IonLabel>
                <IonInput 
                  type="email" 
                  placeholder="Ingrese correo electrónico" 
                  className="inputRegister" 
                  id='email' 
                  name='email' 
                  value={formData.email}
                  onBlur={e => setFormData({ ...formData, [e.target.name]: e.target.value })} 
                  required
                />
              </IonItem>

              <IonItem className='itemRegister'  lines="none">
                <IonLabel position="stacked" className='labelRegister'>Teléfono</IonLabel>
                <IonInput 
                  type="tel" 
                  placeholder="Ingrese teléfono" 
                  className="inputRegister" 
                  id='phone' 
                  name='phone' 
                  maxlength={10}
                  minlength={10}
                  value={formData.phone} 
                  onBlur={e => setFormData({ ...formData, [e.target.name]: e.target.value })}
                  required
                />
              </IonItem>

              <IonItem className='itemRegister'  lines="none">
                <IonLabel position="stacked" className='labelRegister'>Código</IonLabel>
                <IonInput 
                  type="text" 
                  placeholder="Ingrese código" 
                  className="inputRegister" 
                  id='doctor_code' 
                  inputmode="numeric"
                  name='doctor_code' 
                  pattern="[0-9]*"
                  maxlength={10}
                  minlength={10}
                  value={formData.doctor_code}
                  onBlur={e => setFormData({ ...formData, [e.target.name]: e.target.value })}
                  required
                />
              </IonItem>

              <IonItem className='itemRegister'  lines="none">
                <IonLabel position="stacked" className='labelRegister'>Contraseña</IonLabel>
                <IonInput 
                  type="password" 
                  placeholder="Ingrese contraseña" 
                  className="inputRegister" 
                  id='password' 
                  name='password' 
                  value={formData.password}
                  onBlur={e => setFormData({ ...formData, [e.target.name]: e.target.value })}
                  required
                  minlength={8}
                />
              </IonItem>
              <div className=' terminoswrap'>

                <IonCheckbox 
                  slot="start" 
                  id='terms' 
                  name='terms'
                  checked={isChecked} 
                  onIonChange={handleCheckboxChange}
                  />
                <IonLabel className="white ion-text-wrap" > He leido y acepto los 
                  <IonText className='terminoslink' onClick={handleShow}  > términos y condiciones </IonText>
                </IonLabel>
              </div>

              <IonButton expand="block" className='submitBtnRegister' type='submit'>Enviar</IonButton>
            </form>
          </IonCol>
        </IonRow>
      </IonGrid>
     
      <IonModal ref={modal} trigger="open-modal" isOpen={show} >
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="end">
                <IonButton  onClick={handleHide} >Ok</IonButton>
              </IonButtons>
              
            </IonToolbar>
          </IonHeader>
          <IonContent className='terminos' >
            <div className='terminos'>
                <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Reiciendis, delectus! Hic perferendis nam natus tempore nostrum omnis impedit officiis praesentium vitae eos, inventore est laboriosam quis obcaecati incidunt voluptatem! Dicta.</p>
                <p>Harum, nesciunt. Non nobis iure explicabo qui nemo. Ipsam, cupiditate dolorum doloribus corporis explicabo aperiam aliquam praesentium, rerum cum velit eum vel fuga quidem necessitatibus saepe odio temporibus, fugiat repellat?</p>
                <p>Illum ex quidem fugiat corrupti commodi autem alias exercitationem, ducimus pariatur dolorum numquam odit suscipit id at quis perferendis officia deserunt beatae sequi! Sint, rerum. Nemo sint excepturi ipsum nesciunt.</p>
                <p>Nam veritatis omnis excepturi aliquam earum at tenetur ex debitis eveniet dolores temporibus voluptatem quidem blanditiis nisi cumque molestiae qui iure distinctio fuga, rem accusamus praesentium? Autem quos enim voluptatibus.</p>
                <p>Perspiciatis ratione minima sunt perferendis. Quisquam aspernatur vel esse nihil et quis vero repellat delectus iure asperiores. Possimus, architecto minus? Culpa iste porro totam! Maiores commodi deleniti quas inventore excepturi!</p>
                <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Reiciendis, delectus! Hic perferendis nam natus tempore nostrum omnis impedit officiis praesentium vitae eos, inventore est laboriosam quis obcaecati incidunt voluptatem! Dicta.</p>
                <p>Harum, nesciunt. Non nobis iure explicabo qui nemo. Ipsam, cupiditate dolorum doloribus corporis explicabo aperiam aliquam praesentium, rerum cum velit eum vel fuga quidem necessitatibus saepe odio temporibus, fugiat repellat?</p>
                <p>Illum ex quidem fugiat corrupti commodi autem alias exercitationem, ducimus pariatur dolorum numquam odit suscipit id at quis perferendis officia deserunt beatae sequi! Sint, rerum. Nemo sint excepturi ipsum nesciunt.</p>
                <p>Nam veritatis omnis excepturi aliquam earum at tenetur ex debitis eveniet dolores temporibus voluptatem quidem blanditiis nisi cumque molestiae qui iure distinctio fuga, rem accusamus praesentium? Autem quos enim voluptatibus.</p>
                <p>Perspiciatis ratione minima sunt perferendis. Quisquam aspernatur vel esse nihil et quis vero repellat delectus iure asperiores. Possimus, architecto minus? Culpa iste porro totam! Maiores commodi deleniti quas inventore excepturi!</p>
                <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Reiciendis, delectus! Hic perferendis nam natus tempore nostrum omnis impedit officiis praesentium vitae eos, inventore est laboriosam quis obcaecati incidunt voluptatem! Dicta.</p>
                <p>Harum, nesciunt. Non nobis iure explicabo qui nemo. Ipsam, cupiditate dolorum doloribus corporis explicabo aperiam aliquam praesentium, rerum cum velit eum vel fuga quidem necessitatibus saepe odio temporibus, fugiat repellat?</p>
                <p>Illum ex quidem fugiat corrupti commodi autem alias exercitationem, ducimus pariatur dolorum numquam odit suscipit id at quis perferendis officia deserunt beatae sequi! Sint, rerum. Nemo sint excepturi ipsum nesciunt.</p>
                <p>Nam veritatis omnis excepturi aliquam earum at tenetur ex debitis eveniet dolores temporibus voluptatem quidem blanditiis nisi cumque molestiae qui iure distinctio fuga, rem accusamus praesentium? Autem quos enim voluptatibus.</p>
                <p>Perspiciatis ratione minima sunt perferendis. Quisquam aspernatur vel esse nihil et quis vero repellat delectus iure asperiores. Possimus, architecto minus? Culpa iste porro totam! Maiores commodi deleniti quas inventore excepturi!</p>
            </div>
          
          </IonContent>
        </IonModal>
    </IonContent>
  );
};

export default Register;
