import React from 'react';
import { IonContent, IonHeader, IonTitle, IonToolbar, IonItem, IonLabel, IonInput, IonRow, IonCol, IonGrid, IonText, IonButton, IonNote, IonButtons } from '@ionic/react';
import './SaveNewPass.css';

const SaveNewPass: React.FC = () => {
  return (
    <IonContent className='blackBG'>
      <IonHeader>
        <IonToolbar className='toolbarTrans'>
          <IonButtons slot="end" className='btnCloseRecuperar'>
            <IonButton>
              <img src='./assets/img/cerrar.png' alt=''/>
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <IonToolbar className='toolbarTrans'>
          <IonTitle className='ion-text-center'>
            <img src='./assets/carnot_logo.png' className='imgRecuperar' alt=""/>
          </IonTitle>
        </IonToolbar>
        <IonText color="primary">
            <h1 className='textRecuperaTitle'>Ingrese su nueva</h1>
            <h1 className='textRecuperaTitle2'>contraseña</h1>
        </IonText>
      </IonHeader>
      <IonGrid>
        <IonRow>
          <IonCol size-md="2"></IonCol>
          <IonCol size-xs="12" size-md="8">
            <p className='textRecuperar'>
            Ingrese una nueva contraseña, está será con la que iniciará sesión a partir de ahora.
            </p>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size-md="2"></IonCol>
          <IonCol size-xs="12" size-md="8">
            <form action="">
              <IonItem className='itemRecuperar' no-lines>
                <IonLabel position="stacked" className='labelRecuperar'>Contraseña</IonLabel>
                <IonInput type="password" placeholder="Ingrese nueva contraseña" className="inputRecuperar" autofocus></IonInput>
                <IonNote slot="error">Invalid email</IonNote>
              </IonItem>
              <IonButton expand="block" className='submitBtnInicioSesion' type='submit'>Guardar</IonButton>
            </form>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonContent>
  );
};

export default SaveNewPass;
