import React, {useState} from 'react';
import { IonList, IonItem, IonSelect, IonSelectOption } from '@ionic/react';
import './SelectDays.css';

const SelectDays: React.FC = () => {
  const [selectedValue, setSelectedValue] = useState('');

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
    localStorage.setItem('total_days', event.target.value);
    console.log(localStorage.getItem('total_days'));
    window.location.reload();
  }

  return (
    <>
      <IonList>
        <IonItem>
          <IonSelect className="selectDays" interface="action-sheet" value={selectedValue} placeholder="Select day" onIonChange={handleSelectChange} color="light">
            <IonSelectOption value="1">1</IonSelectOption>
            <IonSelectOption value="2">2</IonSelectOption>
            <IonSelectOption value="3">3</IonSelectOption>
            <IonSelectOption value="4">4</IonSelectOption>
            <IonSelectOption value="5">5</IonSelectOption>
            <IonSelectOption value="6">6</IonSelectOption>
            <IonSelectOption value="7">7</IonSelectOption>
            <IonSelectOption value="8">8</IonSelectOption>
            <IonSelectOption value="9">9</IonSelectOption>
            <IonSelectOption value="10">10</IonSelectOption>
            <IonSelectOption value="11">11</IonSelectOption>
            <IonSelectOption value="12">12</IonSelectOption>
            <IonSelectOption value="13">13</IonSelectOption>
            <IonSelectOption value="14">14</IonSelectOption>
            <IonSelectOption value="15">15</IonSelectOption>
            <IonSelectOption value="16">16</IonSelectOption>
            <IonSelectOption value="17">18</IonSelectOption>
            <IonSelectOption value="19">19</IonSelectOption>
            <IonSelectOption value="20">20</IonSelectOption>
            <IonSelectOption value="21">21</IonSelectOption>
            <IonSelectOption value="22">22</IonSelectOption>
            <IonSelectOption value="23">23</IonSelectOption>
            <IonSelectOption value="24">24</IonSelectOption>
            <IonSelectOption value="25">25</IonSelectOption>
            <IonSelectOption value="26">26</IonSelectOption>
            <IonSelectOption value="27">27</IonSelectOption>
            <IonSelectOption value="28">28</IonSelectOption>
            <IonSelectOption value="29">29</IonSelectOption>
            <IonSelectOption value="30">30</IonSelectOption>
            <IonSelectOption value="31">31</IonSelectOption>
            <IonSelectOption value="32">32</IonSelectOption>
            <IonSelectOption value="33">33</IonSelectOption>
            <IonSelectOption value="34">34</IonSelectOption>
            <IonSelectOption value="35">35</IonSelectOption>
            <IonSelectOption value="36">36</IonSelectOption>
            <IonSelectOption value="37">37</IonSelectOption>
            <IonSelectOption value="38">38</IonSelectOption>
            <IonSelectOption value="39">39</IonSelectOption>
            <IonSelectOption value="40">40</IonSelectOption>
            <IonSelectOption value="41">41</IonSelectOption>
            <IonSelectOption value="42">42</IonSelectOption>
            <IonSelectOption value="43">43</IonSelectOption>
            <IonSelectOption value="44">44</IonSelectOption>
            <IonSelectOption value="45">45</IonSelectOption>
          </IonSelect>
        </IonItem>
      </IonList>
    </>
  ); 
}

export default SelectDays;